<template>
  <div class="data-contain">
    <div class="card-wrapper">
      <div class="monitor-search">
        <div class="monitor-date">
          <div class="select-contain fl">
            <i-select :model.sync="uploadType" clearable placeholder="采集方式" style="width:200px" @on-change="need_type_change">
              <i-option value="2">一体机</i-option>
              <i-option value="1">APP</i-option>
              <i-option value="0">手动</i-option>
            </i-select>
          </div>
          <Date-picker format="yyyy-MM-dd" type="daterange" placeholder="选择日期" @on-change="handleChangeTime"></Date-picker>
        </div>
        <div class="tab-contain">
          <router-link class="static-button" :to="{name: 'BloodSugar'}" >统计显示</router-link>
          <router-link :to="{name: 'BloodSugarTable'}">数据显示</router-link>
          <router-link class="chart-button" :to="{name: 'BloodSugarChart'}">图表显示</router-link>
        </div>
      </div>
    </div>
    <div class="add-box">
      <Row>
        <Col span="3" offset="19">
          <a href="javascript:;" @click="handleStander" class="stander">血糖参考标准</a>
        </Col>
        <Col span="2">
          <div class="member-oprate" v-show="power.indexOf('会员管理--编辑会员信息权限') > -1">
            <router-link class="new-doc fr" :to="{name: 'BloodSugarChange'}">添加信息</router-link>
          </div>
        </Col>
      </Row>
    </div>

    <div class="table-box">
      <Table :columns="columns" :data="sugarData" ref="table" @on-selection-change="handleSelect"></Table>

      <Row>
        <Col span="12">
          <div class="table-oprate">
            <!-- <Checkbox>全选</Checkbox> -->
            <Button style="background: #fff;" @click="handleAllRemove" v-show="power.indexOf('会员管理--删除会员信息权限') > -1">删除</Button>
          </div>
        </Col>
        <Col span="12">
          <span class="records">共{{pageTotal}}条记录</span>
        </Col>
      </Row>
    </div>

    <div class="page-nations-wrapper">
      <div class="page-nations">
        <Page :total="pageTotal" :current="BloodSugar.page" :page-size="BloodSugar.size" @on-change="changePage"></Page>
      </div>
    </div>

    <!-- Modal -->
    <Modal title="血糖参考标准" v-model="standerModal">
      <div class="modalImg">
        <img src="@/assets/img/01_r1_c4.png" alt="">
      </div>
      <div slot="footer"></div>
    </Modal>

  </div>
</template>
<script type="text/ecmascript-6">
import MemberService from '@/services/memberService';
export default {
	data() {
		return {
			columns: [
				{ type: 'selection', width: 60, align: 'center' },
				{ title: '日期', key: 'test_date', align: 'center' },
				{ title: '采集时间', key: 'test_time', align: 'center' },
				{ title: '采集方式', key: 'type', align: 'center' },
				{ title: '空腹血糖', key: 'empty', align: 'center' },
				{ title: '饭后半小时', key: 'halfhour', align: 'center' },
				{ title: '饭后1小时', key: 'hour', align: 'center' },
				{ title: '饭后2小时', key: 'twohour', align: 'center' },
				{ title: '饭后3小时', key: 'threehour', align: 'center' },
				{ title: '随机血糖', key: 'random_value', align: 'center' },
				{
					title: '操作',
					key: 'action',
					width: 150,
					align: 'center',
					render: (h, params) => {
						if (this.power.indexOf('会员管理--删除会员信息权限') > -1) {
							return h('div', [
								h('a', {
									class: 'icon_edit',
									on: {
										click: () => {
											let id = this.sugarData[params.index].id;
											MemberService.sugarDetail({ id: JSON.stringify(id) }).then(() => {
												this.$router.push({
													name: 'BloodSugarEdit',
													params: { member_id: this.memberId, sugarId: id },
												});
											});
										},
									},
								}),
								h('a', {
									class: 'icon_del',
									on: {
										click: () => {
											this.$Modal.confirm({
												title: '提醒',
												content: '确定删除吗',
												onOk: () => {
													this.handleSingleRemove(params.index);
												},
											});
										},
									},
								}),
							]);
						} else {
							return h('div', [
								// h('a', {
								//   class: 'icon_edit',
								//   style: {
								//     marginRight: 0
								//   },
								//   on: {
								//     click: () => {
								//       let id = this.sugarData[params.index].id
								//       MemberService.sugarDetail({id: JSON.stringify(id)}).then(data => {
								//         this.$router.push({name: 'BloodSugarEdit', params: {member_id: this.memberId, sugarId: id}})
								//       })
								//     }
								//   }
								// })
							]);
						}
					},
				},
			],
			sugarData: [],
			BloodSugar: {
				index: 1,
				type: '',
				start: '',
				end: '',
				page: 1,
				size: 10,
				monitor_id: '',
				need_type: '',
			},
			pageTotal: 0,
			deleteItem: {
				ids: [],
			},
			power: [],
			standerModal: false,
			uploadType: 1,
		};
	},
	computed: {
		monitorId() {
			return this.$route.params.monitorId;
		},
		memberId() {
			return this.$route.params.member_id;
		},
		userInfo() {
			return this.$store.getters.userInfo;
		},
	},
	methods: {
		need_type_change(value) {
			this.BloodSugar.need_type = value;
			this.init();
		},
		handleStander() {
			this.standerModal = true;
		},
		init() {
			let arr = [];
			this.BloodSugar.member_id = this.memberId;
			MemberService.sugarQuery(this.BloodSugar).then((data) => {
				this.sugarData = data.data_show.list;
				this.deleteItem.ids = [];
				this.sugarData.forEach((item) => {
					arr = item.create_time.split(' ');
					item.test_date = arr[0];
					item.test_time = arr[1];
					item.empty = item.empty === 0 ? '' : `${item.empty}mmol/L`;
					item.halfhour = item.halfhour === 0 ? '' : `${item.halfhour}mmol/L`;
					item.hour = item.hour === 0 ? '' : `${item.hour}mmol/L`;
					item.twohour = item.twohour === 0 ? '' : `${item.twohour}mmol/L`;
					item.threehour = item.threehour === 0 ? '' : `${item.threehour}mmol/L`;
					item.random_value = item.random_value === 0 ? '' : `${item.random_value}mmol/L`;
				});
				this.pageTotal = data.data_show.row_size;
				data.data_show.list.forEach((item) => {
					if (item.type === '0') {
						item.type = '手动';
					} else if (item.type === '1') {
						item.type = 'APP';
					} else if (item.type === '2') {
						item.type = '一体机';
					} else {
						item.type = '';
					}
				});
			});
		},
		handleSelect(selection) {
			this.deleteItem.ids = [];
			selection.forEach((item) => {
				this.deleteItem.ids.push(item.id);
			});
		},
		changePage(page) {
			this.BloodSugar.page = page;
			this.init();
		},
		handleChangeTime(value) {
			this.BloodSugar.start = value[0];
			this.BloodSugar.end = value[1];
			this.init();
		},
		// 删除一个会员
		handleSingleRemove(index) {
			let req = [this.sugarData[index].id];
			MemberService.sugarDelete({ id_str: JSON.stringify(req) }).then(() => {
				this.init();
				this.$Message.warning('删除成功');
			});
		},
		// 删除多个会员
		handleAllRemove() {
			if (this.deleteItem.ids.length === 0) {
				this.$Message.warning('请先勾选要删除的数据');
			} else {
				this.$Modal.confirm({
					title: '提醒',
					content: '确定删除所选会员数据',
					onOk: () => {
						this.handleConfirmDelete();
					},
				});
			}
		},
		// 确认删除
		handleConfirmDelete() {
			MemberService.sugarDelete({ id_str: JSON.stringify(this.deleteItem.ids) }).then(() => {
				this.init();
				this.deleteItem.ids = [];
			});
		},
	},
	mounted() {
		this.power = this.userInfo.power;
		this.BloodSugar.monitor_id = this.monitorId;
		this.init();
	},
};
</script>
<style lang="css" scoped>
.data-contain .table-box {
  margin: 0;
}
.ivu-date-picker {
  line-height: normal;
  display: inline-block;
}
</style>
